//
// Navbar
//

@import "../core/navbars/navbar";
@import "../core/navbars/navbar-vertical";
@import "../core/navbars/navbar-search";
@import "../core/navbars/navbar-dropdown";
@import "../core/navbars/navbar-collapse";

.navbar-brand-img {
    max-height: 100% !important;
}