//
// Forms
//

@import "../core/forms/form";
@import "../core/forms/form-validation";
@import "../core/forms/input-group";

.form-group {
    margin-bottom: 10px;
}

.form-control-label {
    margin-left: 5px;
    font-size: 0.7rem;
    margin-bottom: 0;
}

.col-form-label {
    padding-top: 7px;
    padding-bottom: 0px;
    font-size: 0.9em;
    font-weight: 600;
}