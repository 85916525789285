//
// Modal
//

@import "../core/modals/modal";

.modal-custom-lg {
    max-width: calc(80% - (1.75rem * 2));
}

.modal-custom-xl {
    max-width: calc(100% - (1.75rem * 2));
}


@media (max-width: 600px)
{
    .modal-custom-lg {
        max-width: calc(100%);
    }
    
    .modal-custom-xl {
        max-width: calc(100%);
    }
}