//
// Avatar
//

@import "../core/avatars/avatar";
@import "../core/avatars/avatar-group";

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
